<template>
    <div class="vue-searchselect">
        <input v-model="search_code"
               class="form-control"
               placeholder="請輸入關鍵字搜尋" />
        <select v-model="select_value"
                class="form-control"
                :name="name">
            <option disabled
                    v-if="msg!=''">{{msg}}</option>
            <option value="">{{placeholder}}</option>
            <option v-for="(o,ok) in search_options"
                    :value="o.key"
                    :key="`option_${ok}`"
                    :disabled="o.disabled">{{o.value}}</option>
        </select>
    </div>
</template>
<script>
/*
參數說明：
placeholder:未選擇時顯示的文字
options:選項資料(格式:[{key:選項的value,value:顯示的名稱}])
name:表單元件名稱
*/
export default {
  props: ["placeholder", "value", "options", "name"],
  data() {
    return {
      search_code: "",
      msg: "",
      change: false,
    };
  },
  watch: {
    search_code() {
      this.change = true;
    },
    //   value() {
    //     console.info("searchselect value change", this.value);
    //     this.select_value = this.value;
    //   },
  },
  computed: {
    select_value: {
      set(val) {
        this.$emit("input", val);
        this.$emit("change", val);
        this.$forceUpdate();
      },
      get() {
        return this.value;
      },
    },
    search_options() {
      let vue = this;
      if (vue.search_code == "") {
        return vue.options;
      } else if (typeof vue.options === "object") {
        let opt = vue.options.filter((el) => {
          let scode = vue.change_full_text(vue.search_code).split(" ");
          let match = true;
          for (let i in scode) {
            if (vue.change_full_text(el.value).indexOf(scode[i]) == -1) {
              match = false;
            }
          }
          return match;
        });
        if (opt.length > 0) {
          if (vue.change) {
            vue.select_value = opt[0].key;
            vue.change = false;
          }
          vue.msg = "";
        } else {
          vue.msg = "無符合項目";
          vue.select_value = "";
        }
        return opt;
      } else {
        return [];
      }
    },
  },
  methods: {
    change_full_text(str) {
      var tmp = "";
      for (var i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) > 65248 && str.charCodeAt(i) < 65375) {
          tmp += String.fromCharCode(str.charCodeAt(i) - 65248);
        } else {
          tmp += str[i];
        }
      }
      return tmp.toLowerCase();
    },
  },
};
</script>