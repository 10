<template>
    <div>
        <mdb-card class="mb-2"
                  style="margin-top:-1rem">
            <mdb-card-body class="p-2">
                <mdb-row>
                    <mdb-col md="6">
                        <mdb-btn tag="a"
                                 size="sm"
                                 outline="primary"
                                 @click="$router.push('/repair/estimate/list')">
                            <i class="fa fa-search"></i>
                            估價單查詢
                        </mdb-btn>
                    </mdb-col>
                    <mdb-col md="6"
                             id="btn-print"
                             class="text-right"
                             v-if="repair_estimate.id!=''">
                        <mdb-btn size="sm"
                                 outline="secondary"
                                 @click="print_data">
                            <i class="fa fa-print"></i>
                            列印
                        </mdb-btn>
                    </mdb-col>
                </mdb-row>
            </mdb-card-body>
        </mdb-card>
        <mdb-card class="mb-2">
            <mdb-card-body class="pt-0">
                <mdb-row>
                    <mdb-col md="4">
                        <datepicker label="送件日期"
                                    v-if="repair_estimate.id==''"
                                    v-model="repair_estimate.day"></datepicker>
                        <mdb-input label="送件日期"
                                   disabled
                                   v-else
                                   v-model="repair_estimate.day" />
                    </mdb-col>
                    <mdb-col md="4">
                        <mdb-select label="收件門市"
                                    :disabled="repair_estimate.id!=''"
                                    v-model="storeList"
                                    @getValue="val=>repair_estimate.id_store=val"></mdb-select>
                    </mdb-col>
                    <mdb-col md="4">
                        <mdb-input label="單號"
                                   type="text"
                                   disabled
                                   v-model="repair_estimate.no" />
                    </mdb-col>
                </mdb-row>
                <mdb-row>

                    <mdb-col md="4">
                        <mdb-input label="客戶姓名"
                                   type="text"
                                   :disabled="repair_estimate.id!=''"
                                   v-model="repair_estimate.name" />
                    </mdb-col>
                    <mdb-col md="4">
                        <mdb-input label="聯絡電話"
                                   type="text"
                                   :disabled="repair_estimate.id!=''"
                                   v-model="repair_estimate.cel" />
                    </mdb-col>
                    <mdb-col md="4">
                        <mdb-select label="方便聯絡時間"
                                    v-model="connect_time"
                                    v-if="repair_estimate.id==''"
                                    @getValue="val=>repair_estimate.connect_time=val" />
                        <mdb-input label="方便聯絡時間"
                                   disabled
                                   v-else
                                   v-model="repair_estimate.connect_time" />
                    </mdb-col>
                    <mdb-col md="4">
                        <mdb-select label="機型"
                                    search
                                    v-if="repair_estimate.id==''"
                                    v-model="repair_targetList"
                                    @getValue="val=>repair_estimate.id_repair_target=val"></mdb-select>
                        <mdb-input label="機型"
                                   disabled
                                   v-else
                                   :value="repair_targetKeyList[repair_estimate.id_repair_target] ? repair_targetKeyList[repair_estimate.id_repair_target].text : ''" />
                    </mdb-col>
                    <mdb-col md="4"
                             v-if="repair_targetKeyList[repair_estimate.id_repair_target] || repair_estimate.id!=''">
                        <mdb-select label="顏色"
                                    v-if="repair_estimate.id==''"
                                    v-model="repair_targetKeyList[repair_estimate.id_repair_target].colors"
                                    @getValue="val=>repair_estimate.color=val"></mdb-select>
                        <mdb-input label="顏色"
                                   disabled
                                   v-else
                                   v-model="repair_estimate.color" />
                    </mdb-col>
                    <mdb-col md="4"
                             v-else>
                        <mdb-input type="text"
                                   label="顏色"
                                   disabled />
                    </mdb-col>
                    <mdb-col md="4">
                        <mdb-input label="序號"
                                   type="text"
                                   :disabled="repair_estimate.id!=''"
                                   v-model="repair_estimate.code" />
                    </mdb-col>
                </mdb-row>
                <mdb-row>
                    <mdb-col md="4">
                        <mdb-select label="外觀狀況"
                                    placeholder="請選取外觀狀況"
                                    :disabled="repair_estimate.id!=''"
                                    :options="option_appearance"
                                    @getValue="val=>repair_estimate.appearance=val"></mdb-select>
                    </mdb-col>
                </mdb-row>
                <mdb-row>
                    <mdb-col md="12">
                        <mdb-input type="textarea"
                                   label="客戶敘述故障原因"
                                   :disabled="repair_estimate.id!=''"
                                   v-model="repair_estimate.problem"></mdb-input>
                    </mdb-col>
                </mdb-row>
                <mdb-row>
                    <mdb-col md="12">
                        <mdb-input type="textarea"
                                   label="工程師檢修狀況"
                                   :disabled="repair_estimate.id!=''"
                                   v-model="repair_estimate.overhaul"></mdb-input>
                    </mdb-col>
                </mdb-row>
                <mdb-row v-if="repair_estimate.id==''">
                    <mdb-col md="6">
                        <searchSelect placeholder="===請選擇==="
                                      :options="productList"
                                      v-model="select_product"></searchSelect>
                    </mdb-col>
                    <mdb-col md="3">
                        <mdb-btn size="sm"
                                 color="primary"
                                 @click="add_item()">
                            <i class="fa fa-plus"></i>
                            增加
                        </mdb-btn>
                    </mdb-col>
                </mdb-row>
                <mdb-row>
                    <mdb-col md="12">
                        <table class="table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th style="width:2rem">#</th>
                                    <th style="width:8rem">服務項目</th>
                                    <th>品名</th>
                                    <th style="width:5rem">數量</th>
                                    <th style="width:5rem">單價</th>
                                    <th style="width:10rem">小計</th>
                                    <th style="width:8rem"
                                        v-show="repair_estimate.id==''"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(p,pk) in repair_estimate.item_list"
                                    :key="`p_${pk}`">
                                    <td>{{pk+1}}</td>
                                    <td>
                                        <mdb-input type="text"
                                                   class="m-0"
                                                   v-show="repair_estimate.id==''"
                                                   :min="1"
                                                   v-model="p.device"></mdb-input>
                                        {{repair_estimate.id!='' ? p.device : ""}}
                                    </td>
                                    <td>
                                        <small>{{productKeyList[p.id_product].code}}</small><br>
                                        {{p.name}}
                                    </td>
                                    <td>
                                        <mdb-input type="number"
                                                   class="m-0"
                                                   v-show="repair_estimate.id==''"
                                                   :min="1"
                                                   v-model.number="p.num"></mdb-input>
                                        {{repair_estimate.id!='' ? p.num : ""}}
                                    </td>
                                    <td>
                                        <mdb-input type="number"
                                                   class="m-0"
                                                   v-show="repair_estimate.id==''"
                                                   :min="0"
                                                   v-model="p.price" />{{repair_estimate.id!='' ? p.price : ""}}
                                    </td>
                                    <td>{{$numeral(p.price*p.num).format('0,0')}}</td>
                                    <td v-show="repair_estimate.id==''">
                                        <mdb-btn color="danger"
                                                 size="sm"
                                                 @click="repair_estimate.item_list.splice(pk,1)">
                                            <i class="fa fa-trash"></i>
                                        </mdb-btn>
                                    </td>
                                </tr>
                                <tr v-if="repair_estimate.item_list.length>0">
                                    <td colspan="5">合計</td>
                                    <td>{{$numeral(get_total_prod.total).format('0,0')}}</td>
                                    <td v-show="repair_estimate.id==''"></td>
                                </tr>
                                <tr v-if="repair_estimate.item_list.length==0">
                                    <td colspan="7">無資料</td>
                                </tr>
                                <tr v-else>
                                    <td colspan="7">
                                        共{{repair_estimate.item_list.length}}項，{{get_total_prod.num}}個
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </mdb-col>
                </mdb-row>
                <mdb-row>
                    <mdb-col md="4">
                        <mdb-select label="付款方式"
                                    placeholder="請選取付款方式"
                                    :disabled="repair_estimate.id!=''"
                                    v-model="paymentList"
                                    @getValue="val=>repair_estimate.payment=val"></mdb-select>
                    </mdb-col>
                    <mdb-col md="4">
                        <mdb-input label="訂金"
                                   :disabled="repair_estimate.id!=''"
                                   v-model="repair_estimate.deposit"></mdb-input>
                    </mdb-col>
                </mdb-row>
                <mdb-row>
                    <mdb-col md="12">
                        <mdb-input type="textarea"
                                   label="其他告知項目"
                                   :disabled="repair_estimate.id!=''"
                                   v-model="repair_estimate.remark"></mdb-input>
                    </mdb-col>
                </mdb-row>
            </mdb-card-body>
        </mdb-card>
        <div class="text-right mb-4"
             v-if="repair_estimate.id==''">
            <mdb-btn color="default"
                     @click="save_data()">
                <i class="fa fa-save"></i>
                &nbsp;儲存
            </mdb-btn>
        </div>
        <!-- 列印用畫面 -->
        <div class="d-none"
             v-if="repair_estimate.id!=''">
            <div id="print_content"
                 ref="print_frame">
                <table border="1"
                       width="100%"
                       cellpadding="10"
                       style=" border-collapse: collapse;">
                    <tr>
                        <th colspan="6"
                            style="text-align:center;padding:10px 0;">
                            <h1>APEX LEAGUE 維修估價檢測單</h1>
                        </th>
                    </tr>
                    <tr>
                        <th style="text-align:center;font-weight:bold">收件分店</th>
                        <td style="width:21%">
                            {{ storeKeyList[repair_estimate.id_store] ? storeKeyList[repair_estimate.id_store].name : '' }}
                        </td>
                        <th style="text-align:center;font-weight:bold">分店電話</th>
                        <td style="width:21%">
                            {{ storeKeyList[repair_estimate.id_store] ? storeKeyList[repair_estimate.id_store].phone : '' }}
                        </td>
                        <th style="text-align:center;font-weight:bold">送件日期</th>
                        <td style="width:21%">{{repair_estimate.day}}</td>
                    </tr>
                    <tr>
                        <th style="text-align:center;font-weight:bold">分店地址</th>
                        <td colspan="3">
                          {{ storeKeyList[repair_estimate.id_store] ? storeKeyList[repair_estimate.id_store].address : '' }}
                        </td>
                        <th style="text-align:center;font-weight:bold">單號</th>
                        <td>{{repair_estimate.no}}</td>
                    </tr>
                    <tr>
                        <td colspan="6"
                            class="p-1"></td>
                    </tr>
                    <tr>
                        <th style="text-align:center;font-weight:bold">客戶姓名</th>
                        <td>{{repair_estimate.name}}</td>
                        <th style="text-align:center;font-weight:bold">聯絡電話</th>
                        <td>{{repair_estimate.cel}}</td>
                        <th style="text-align:center;font-weight:bold">方便聯絡時間</th>
                        <td>{{repair_estimate.connect_time}}</td>
                    </tr>
                    <tr>
                        <th style="text-align:center;font-weight:bold">機型</th>
                        <td colspan="3">
                            {{ repair_targetKeyList[repair_estimate.id_repair_target] ? repair_targetKeyList[repair_estimate.id_repair_target].text : '' }}
                        </td>
                        <th style="text-align:center;font-weight:bold">顏色</th>
                        <td>
                            {{repair_estimate.color}}
                        </td>
                    </tr>
                    <tr>
                        <th style="text-align:center;font-weight:bold">序號</th>
                        <td colspan="3">{{repair_estimate.code}}</td>
                        <th style="text-align:center;font-weight:bold">外觀狀況</th>
                        <td>{{appearanceList[repair_estimate.appearance]}}</td>
                    </tr>
                    <tr>
                        <th colspan="6"
                            style="text-align:center;font-weight:bold">客戶敘述故障原因</th>
                    </tr>
                    <tr>
                        <td colspan="6"
                            v-html="$nl2br(repair_estimate.problem)"></td>
                    </tr>
                    <tr>
                        <th colspan="6"
                            style="text-align:center;font-weight:bold">工程師檢修狀況</th>
                    </tr>
                    <tr>
                        <td colspan="6"
                            v-html="$nl2br(repair_estimate.overhaul)"></td>
                    </tr>
                    <tr>
                        <td colspan="6"
                            class="p-0 py-1">
                            <table border="1"
                                   width="100%"
                                   cellpadding="10"
                                   style="border-collapse: collapse;">
                                <tr>
                                    <th style="text-align:left;font-weight:bold;width:15%">服務項目</th>
                                    <th style="text-align:left;font-weight:bold ">品名</th>
                                    <th style="text-align:left;font-weight:bold;width:15%">數量</th>
                                    <th style="text-align:left;font-weight:bold;width:15%">單價</th>
                                    <th style="text-align:left;font-weight:bold;width:15%">小計</th>
                                </tr>
                                <tr v-for="(p,pk) in repair_estimate.item_list"
                                    :key="`p_${pk}`">
                                    <td>{{p.device}}</td>
                                    <td>
                                        <small>{{productKeyList[p.id_product].code}}</small><br>
                                        {{p.name}}
                                    </td>
                                    <td>{{$numeral(p.num).format('0,0')}}</td>
                                    <td>{{$numeral(p.price).format('0,0')}}</td>
                                    <td>{{$numeral(p.price*p.num).format('0,0')}}</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <th style="text-align:center;font-weight:bold">付款方式</th>
                        <td>
                          {{ paymentKeyList[repair_estimate.payment] ? paymentKeyList[repair_estimate.payment].name : '' }}
                        </td>
                        <th style="text-align:center;font-weight:bold">訂金</th>
                        <td>{{$numeral(repair_estimate.deposit).format('0,0')}}</td>
                        <th style="text-align:center;font-weight:bold">尾款</th>
                        <td>
                          {{$numeral(get_total_prod.total-repair_estimate.deposit).format('0,0')}}
                        </td>
                    </tr>
                    <tr>
                        <th colspan="6"
                            style="text-align:center;font-weight:bold">注意事項
                        </th>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <p>
                                ◆本公司所有服務皆取得消費者同意報價後進行維修並收取費用。<br>
                                ◆維修保固範圍皆為非人為因素保固，人為問題恕不負責。 <br>
                                ◆泡水3C產品存在許多風險，檢修前店員會告知相關風險，檢修過程中非人員損壞，本公司無需負擔任何法律責任。 <br>
                                ◆本公司所有零件主機板維修項目皆享保固售後服務，有問題請於保固期間內盡快回來處理，保固時間超過本公司無需負擔任何法律責任。 <br>
                                ◆如因任何非不可歸屬於本公司之因素（包括但不限於：零組件缺貨、運送逾時等），致使維修或服務延遲，無法進行或無法完成時，或非產品本身的損害或索賠，本公司無需負擔任何法律責任。
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="6"
                            style="text-align:center;font-weight:bold">其他告知項目
                        </th>
                    </tr>
                    <tr>
                        <td colspan="6"
                            v-html="$nl2br(repair_estimate.remark)"></td>
                    </tr>
                    <tr>
                        <th colspan="3"
                            style="text-align:center;font-weight:bold">客戶簽名
                        </th>
                        <th colspan="3"
                            style="text-align:center;font-weight:bold">門市經手人
                        </th>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <br><br>
                        </td>
                        <td colspan="3">
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6"
                            class="py-1"
                            style="border-left:none;border-right:none;">
                            <div style="border-top:2px dashed #333"></div>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="6"
                            style="text-align:center;font-weight:bold">APEX LEAGUE 維修估價檢測單（客戶收執聯）
                        </th>
                    </tr>
                    <tr>
                        <th style="text-align:center;font-weight:bold">送件日期</th>
                        <td>{{repair_estimate.day}}</td>
                        <th style="text-align:center;font-weight:bold">單號</th>
                        <td>{{repair_estimate.no}}</td>
                        <th style="text-align:center;font-weight:bold">收件分店</th>
                        <td style="width:21%">
                          {{ storeKeyList[repair_estimate.id_store] ? storeKeyList[repair_estimate.id_store].name : '' }}
                        </td>
                    </tr>
                    <tr>
                        <th style="text-align:center;font-weight:bold">分店地址</th>
                        <td colspan="3">
                          {{ storeKeyList[repair_estimate.id_store] ? storeKeyList[repair_estimate.id_store].address : '' }}
                        </td>
                        <th style="text-align:center;font-weight:bold">分店電話</th>
                        <td>
                          {{ storeKeyList[repair_estimate.id_store] ? storeKeyList[repair_estimate.id_store].phone : '' }}
                        </td>
                    </tr>
                    <tr>
                        <th style="text-align:center;font-weight:bold">客戶姓名</th>
                        <td>{{repair_estimate.name}}</td>
                        <th style="text-align:center;font-weight:bold">聯絡電話</th>
                        <td>{{repair_estimate.cel}}</td>
                        <th style="text-align:center;font-weight:bold">方便聯絡時間</th>
                        <td>{{repair_estimate.connect_time}}</td>
                    </tr>
                    <tr>
                        <th style="text-align:center;font-weight:bold">機型</th>
                        <td colspan="3">
                            {{ repair_targetKeyList[repair_estimate.id_repair_target] ? repair_targetKeyList[repair_estimate.id_repair_target].text : '' }}
                        </td>
                        <th style="text-align:center;font-weight:bold">顏色</th>
                        <td>
                            {{repair_estimate.color}}
                        </td>
                    </tr>
                    <tr>
                        <th style="text-align:center;font-weight:bold">序號</th>
                        <td colspan="3">{{repair_estimate.code}}</td>
                        <th style="text-align:center;font-weight:bold">外觀狀況</th>
                        <td>{{appearanceList[repair_estimate.appearance]}}</td>
                    </tr>
                    <tr>
                        <th colspan="6"
                            style="text-align:center;font-weight:bold">工程師檢修狀況</th>
                    </tr>
                    <tr>
                        <td colspan="6"
                            v-html="$nl2br(repair_estimate.overhaul)"></td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {
  mdbCard,
  mdbCardBody,
  mdbRow,
  mdbCol,
  mdbInput,
  mdbSelect,
  mdbBtn,
} from "mdbvue";
import datepicker from "../../components/datepicker";
import SearchSelect from "../../components/SearchSelect";
export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbInput,
    mdbSelect,
    mdbRow,
    mdbCol,
    mdbBtn,
    datepicker,
    SearchSelect,
  },
  data() {
    return {
      repair_estimate: {
        item_list: [],
      },
      storeList: [],
      storeKeyList: [],
      repair_targetList: [],
      repair_targetKeyList: [],
      productList: [{ text: "清單讀取中" }],
      productKeyList: [],
      select_product: "",
      appearanceList: {
        1: "優良",
        2: "輕度外傷",
        3: "嚴重外傷",
        4: "變形",
      },
      // 方便聯絡時間
      connect_time: [
        {
          value: "9-12",
          text: "9-12",
          selected: false,
        },
        {
          value: "13-20",
          text: "13-20",
          selected: false,
        },
      ],
      option_appearance: [],
      paymentList: [],
      paymentKeyList: {},
    };
  },
  watch: {
    "$route.path": {
      handler() {
        // if (this.$route.path == "/repair/estimate") {
        location.reload();
        // }
      },
      deep: true,
    },
  },
  mounted() {
    console.clear();
    this.get_data();
  },
  computed: {
    get_total_prod() {
      let output = {
        total: 0,
        num: 0,
      };
      this.repair_estimate.item_list.forEach((item) => {
        output.total += item.price * item.num;
        output.num += item.num;
      });
      return output;
    },
  },
  methods: {
    get_data() {
      let vue = this,
        query = [
          {
            name: "opt_payment",
            data: {},
          },
          {
            name: "product",
            data: {
              active: {
                type: 0,
                value: "1",
              },
            },
            sort: {
              name:"0",
            }
          },
          {
            name: "repair_target",
            data: {
              active: {
                type: 0,
                value: "1",
              },
            },
          },
          {
            name: "store",
            data: {
              active: {
                type: 0,
                value: "1",
              },
            },
          },
        ];
      if (vue.$route.params.id) {
        query.push({
          name: "repair_estimate",
          data: {
            id: {
              type: 0,
              value: vue.$route.params.id,
            },
          },
        });
      } else {
        vue.repair_estimate = {
          item_list: [],
        };
        query.push({
          name: "repair_estimate",
        });
      }
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `data/get_data/?query=${JSON.stringify(query)}`,
          },
        })
        .then((res) => {
          // 維修估價
          if (vue.$route.params.id) {
            vue.repair_estimate = res.data.repair_estimateList[0];
            vue.repair_estimate.item_list = JSON.parse(
              vue.repair_estimate.item_list
            );
          } else {
            vue.repair_estimate = res.data.repair_estimateEmpty;
            for (let k in vue.repair_estimate) {
              vue.repair_estimate[k] = "";
            }
            vue.$set(vue.repair_estimate, "item_list", []);
            vue.repair_estimate.day = vue.$moment().format("YYYY-MM-DD");
            vue.repair_estimate.id_store = vue.$store.state.id_store;
            vue.repair_estimate.id_admin = vue.$store.state.user.id;
          }
          // 外觀狀況
          for (let idx in vue.appearanceList) {
            let item = vue.appearanceList[idx];
            vue.option_appearance.push({
              value: idx,
              text: item,
              selected: idx == vue.repair_estimate.appearance,
            });
          }
          // 付款方式
          vue.paymentList = res.data.opt_paymentList.map((item) => {
            vue.paymentKeyList[item.id] = item;
            return {
              value: item.id,
              text: item.name,
              selected: item.id == vue.repair_estimate.payment,
            };
          });
          // 維修機種
          vue.repair_targetList = res.data.repair_targetList.map((rt) => {
            rt.colors = JSON.parse(rt.colors).map((c) => {
              return {
                text: c,
                value: c,
                selected: false,
              };
            });
            // 如果沒有顏色資料就補一個空值
            rt.colors =
              rt.colors.length > 0
                ? rt.colors
                : [{ text: "-", value: "", selected: false }];
            vue.repair_targetKeyList[rt.id] = rt;
            rt.selected = false;
            rt.text = rt.name;
            rt.value = rt.id;
            return rt;
          });
          // 分店
          vue.storeList = [];
          vue.storeKeyList = [];
          res.data.storeList.forEach((item) => {
            vue.storeList.push({
              text: item.name,
              value: item.id,
              selected: item.id == vue.$store.state.id_store,
            });
            vue.storeKeyList[item.id] = item;
          });
          // 商品
          vue.productList = [];
          vue.productKeyList = [];
          res.data.productList.forEach((item) => {
            vue.productList.push({
              key: item.id,
              value: `${item.name} ( ${item.code} )`,
            });
            vue.productKeyList[item.id] = {
              name: item.name,
              code: item.code,
              price: item.price,
            };
            return item;
          });
        });
    },
    add_item() {
      let vue = this;
      if (this.select_product != "" && this.select_product != null) {
        let index = this.repair_estimate.item_list.findIndex((item) => {
          return item.id_product == this.select_product;
        });
        if (index == -1) {
          this.repair_estimate.item_list.push({
            device: "",
            id_product: this.select_product,
            name: vue.productKeyList[this.select_product].name,
            num: 1,
            price: vue.productKeyList[this.select_product].price,
          });
        }
      }
      this.select_product = "";
    },
    // 儲存估價資料
    save_data() {
      let vue = this,
        msg = "";
      switch (true) {
        case vue.repair_estimate.name == "":
          msg = "請填寫客戶姓名";
          break;
        case vue.repair_estimate.cel == "":
          msg = "請填寫聯絡電話";
          break;
        case vue.repair_estimate.id_store == "":
          msg = "請選擇收件門市";
          break;
        case vue.repair_estimate.item_list.length == 0:
          msg = "請新增維修項目";
          break;
      }
      if (msg == "") {
        vue.$swal.fire({
          icon: "info",
          title: "確定要儲存估價資料嗎?",
          showCancelButton: true,
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          preConfirm() {
            return vue.$store
              .dispatch("post_form", {
                payload: {
                  url: "repair/estimate_process",
                  data: { data: JSON.stringify(vue.repair_estimate) },
                },
              })
              .then((res) => {
                if (res.data.status == "ok") {
                  vue.$swal
                    .fire({
                      icon: "success",
                      title: "儲存完成",
                      timer: 1500,
                      showConfirmButton: false,
                    })
                    .then(() => {
                      vue.$router.push(
                        "/repair/estimate/view/" + res.data.id_repair_estimate
                      );
                    });
                }
              });
          },
        });
      } else {
        vue.$swal.fire({
          title: msg,
          icon: "warning",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    print_data() {
      let html = `
      <html>
        <head>
          <title>列印差旅費</title>
        </head>
        <body onload='window.print();window.close()'>
          ${this.$refs.print_frame.innerHTML}
          <style>
            table{
              display:none;
            }
            @media print{
              table{
                display:table
              }
            }
          </style>
        </body>
      </html>`;

      let printPage = window.open("", "Printing...", "");
      printPage.document.open();
      printPage.document.write(html);
      printPage.document.close();
    },
  },
};
</script>