<template>
    <mdb-date-picker :icon="icon"
                     :label="label"
                     :autoHide="true"
                     :disableOk="true"
                     :disableClear="true"
                     :disableToday="true"
                     :option="get_option"
                     :limit="get_limit"
                     v-model="date"></mdb-date-picker>
</template>
<script>
import { mdbDatePicker } from "mdbvue";
import moment from "moment";
/**
 * limit 是一個物件陣列 每一個物件都是限制條件{type:'fromto | from | to',from:'',to:''}
 */
export default {
  name: "DatePicker",
  components: {
    mdbDatePicker,
  },
  props: {
    value: { default: () => "" },
    label: { default: () => "" },
    min: { default: () => "" },
    max: { default: () => "" },
    icon: { default: () => "calendar-alt" },
    limit: { default: () => [] },
    option: { default: () => new Object() },
  },
  data() {
    return {
      // 日期選擇器預設選項
      default_option: {
        month: [
          "一月",
          "二月",
          "三月",
          "四月",
          "五月",
          "六月",
          "七月",
          "八月",
          "九月",
          "十月",
          "十一月",
          "十二月",
        ],
        week: ["一", "二", "三", "四", "五", "六", "日"],
        buttons: {
          ok: "確定",
          clear: "清除",
          today: "今天",
        },
      },
    };
  },
  watch: {},
  computed: {
    date: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    get_option() {
      return Object.assign({}, this.default_option, this.option);
    },
    get_limit() {
      let output = [];
      if (this.max != "") {
        output.push({ type: "from", from: moment(this.max) });
      }
      if (this.min != "") {
        output.push({ type: "to", to: moment(this.min) });
      }
      return this.limit.concat(output);
    },
  },
};
</script>